  const ovaleJournal = [
    {
      id: 1,
      date: 'DATE: ███████ TIME: ████████',
      title: 'First Impressions',
      content: `Today was my first day at Demiurge Inc. It’s not often a scientist gets to say they’ve entered the very cutting edge of reality manipulation, and yet here I am. 
  
  Dr. Fletcher greeted me personally, which I’ll admit was unexpected. He has an aura of quiet authority, the kind that makes you stand a little straighter just by being in the same room. He handed me a folder marked “Project ZEITGEIST,” then immediately launched into a discussion about dimensional energy frequencies as though I’d been part of the team for years. I appreciate the confidence, but I’ll need a few days to fully orient myself.
  
  The lab is certainly unique. I’ve worked at CERN, but this place feels like someone turned every impossible theory into hardware. There’s a hallway of containment units labeled “Temporal Instability – ACTIVE,” a glowing rift in the east wing, and what I can only describe as a spatial anomaly hovering over Lab 7. When I asked Jenkins - apparently the unofficial guide - to explain it, he said, “That’s just the Monday ripple. You get used to it.” Used to it? 
  
  The team seems competent. Experimental logs from Fletcher and others indicate they’ve stabilized rift formation within narrow tolerances, but the Collapse Model mentioned in my onboarding documents keeps gnawing at me. I can’t shake the feeling that stabilizing dimensional rifts is only the beginning. There’s something they’re not telling me. Something big.
  
  I was given a desk in an office labeled ‘Director of Temporal and Dimensional Studies.’ Quite the title. It’s too soon to say if this will be the crowning achievement of my career or the point at which my obituary reads “he flew too close to the (black hole) sun.” 
  
  Either way, the journey starts here.`,
    }, 

    {
      id: 2,
      date: 'DATE: ███████ TIME: ████████',
      title: 'A Week in the Lab',
      content: `It's been a week now at Demiurge here, and the strangeness of this place has only gotten more intriguing. My office still feels more like a set piece from a sci-fi movie than a workplace - a sleek desk made of some iridescent alloy (not steel), equipment that I can’t even begin to name (and have to use every day), and a whiteboard already covered with notes I didn’t write (but it's my handwriting). I’m told it belonged to the last Director of Temporal and Dimensional Studies, but no one will tell me what happened to them. 
    
    Today, Dr. Fletcher invited me to observe one of his experiments. A privilige, I’m told. Apparently, the Collapse Model I’d read about in my onboarding documents wasn’t just theoretical. In the east wing, they have what’s referred to as the “Singularity Pen,” a contained micro-kugelblitz - a man-made black hole stabilized through quantum resonance dampeners. Fletcher described it as a “proof of concept,” but standing in front of it, I felt less like a scientist and more like an ant staring at the sun. The containment field hums with this strange, resonant frequency that you can feel in your chest. And when I stared at it for too long, I swear I heard some kind of whispering. 
    
   Fletcher explained how they’ve been using the Pen to conduct chrono-particle experiments, essentially creating tiny bubbles of frozen time. He said, “Imagine if we could pause reality in a specific area, observe it at the subatomic level, or even alter its flow.” The implications are staggering, and frankly, terrifying. But he’s so calm about it. It’s like he’s seen this all before, like he’s already done the math on every risk. 
    
    Afterward, we sat in his office and talked for hours. He wanted to hear about my time at CERN, particularly my research into high-energy particle collisions and temporal field interactions. He seemed genuinely impressed, which is not a reaction I’m used to. At one point, he leaned back and said, “You know, Orion, most people who work here spend their whole careers trying to keep up with the unknown. You’re already running with it.” 
    
    I didn’t know what to say to that, so I asked him why he stayed. His answer surprised me: “Because there’s always one more question to answer.” Simple, but it stuck with me. 
    
    We ended the day in Lab 5, where they’re developing a device called the Temporal Inversion Array. It’s supposed to reverse local time fields. The prototype is temperamental. Jenkins accidentally turned a screwdriver into a pile of primordial ooze before we called it a day. 
    
    Still, I feel something here - a sense of purpose, of belonging. This isn’t just a job; it’s a frontier. And I think, for the first time in a long time, I might have found someone who sees it the same way I do.`
    },
    

    {
      id: 3,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Peculiar Gravity of Lab 4',
      content: `It’s been almost a month since I joined Demiurge, and I’m still not entirely sure how to describe this place. Every day feels like the opening chapter of a sci-fi novel no one could ever finish. I’ve been spending more time in Lab 4 with Dr. Fletcher, who, despite his cryptic nature, is becoming something of an anchor for me in this swirling chaos of quantum absurdity. 
    
    Today’s experiment was something straight out of the fringe of theoretical physics. Fletcher and his team were working on stabilizing micro-singularities for a project ominously titled “Temporal Pocket Arrays.” The idea was simple enough in theory: we create a localized time bubble where temporal flow could be accelerated, slowed, or entirely stopped. But in practice? Total fucking chaos. Beautiful, terrifying chaos.
    
    The test subject was a grapefruit. A single grapefruit. Fletcher called it “low stakes” to reassure me. The moment the array activated, the grapefruit shimmered like it was being refracted through water. Within seconds, it began to vibrate at frequencies I could feel in my teeth. Then, with a sound like a plucked piano string - it split. Not exploded - split. One half aged into a shriveled, blackened husk, while the other remained pristine, and possibly immortal, outside of time itself, frozen and eternally fresh.
    
    “Interesting,” Fletcher said, as though this was entirely expected. I asked him what had just happened, and he replied, “Temporal harmonics. The singularity field must have shifted mid-stabilization. This one’s on me - I forgot to account for cross-dimensional resonance.”
    
    Of course. Cross-dimensional resonance. How silly of us to forget.
    
    Later, Fletcher pulled me aside to walk through the math. He has this way of explaining things that makes the impossible sound almost mundane. “Think of time as a fabric,” he said, “but not one you can cut or sew. It’s more like a Möbius strip, looping and folding into itself. Sometimes, the thread gets tangled. But it's all the same thread.” 
    
    I still don’t entirly understand what he means, but I’m beginning to see the pattern in his thinking. Fletcher isn’t just a scientist; he’s a philosopher of the impossible. And he seems to think I have a knack for it, too. I don’t know why that means so much to me, but it does.
    
    The rest of the day passed in a blur of recalibrations and increasingly bizarre anomalies. At one point, Jenkins wandered into the lab holding a tray of sandwiches, only to find himself temporarily stuck in what Fletcher called a “time ripple.” For about twenty seconds, he moved in slow motion while everyone else stayed at normal speed. The sandwiches weren’t so lucky - they were caught halfway between states and ended up looking like something out of a surrealist painting, smeared and glitching. Jenkins didn’t seem to mind. “Happens all the time,” he said, as if that explained anything. Then he ate one. He ate a glitching sandwich.
    
    I’m starting to feel a strange affection for this place. It’s dangerous, unpredictable, and occasionally absurd, but there’s something exhilarating about standing at the edge of the unknown. Fletcher says that’s how he felt when he first started here, too. “Give it time,” he said, “and it’ll either kill you or show you wonders you never imagined. Maybe both.”
    
    For now, I’m leaning into the wonders.`
    },

    {
      id: 4,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Weight of Protocol-7',
      content: `Today, we lost twelve people.
    
    I’ve been sitting at my desk for hours, staring at the report. Twelve names, each followed by the same word: DECEASED.” It’s clinical, cold, ALL CAPS - like it's yelling at me. But there’s no way to make this feel less catastrophic. They trusted me. I led them into that anomaly, and now they’re gone.
    
    Protocol-7 was supposed to be a breakthrough - a way to stabilize dimensional rifts and make interdimensional travel safer. For three hours, it worked. The Reality Stabilization Field was holding. We thought we had finally achieved something meaningful. But then the energy readings spiked, and everything began to unravel. 
    
    The feeds... I keep replaying them in my mind. The way the environment twisted, folding in on itself like crumpled paper. The screams from the team as they described being pulled in “every direction at once.” And then - silence. Just silence. The RSF device is gone, the data is gone, and those twelve people - theyre gone, too.
    
    I can’t stop thinkng about their faces. I knew them. I worked with them. They weren’t just colleagues, they were people. And I led them to their deaths.
    
    I was ready to walk away tonight. To leave this lab, this work, and never look back. What right do I have to continue, knowing what my decisions cost? But Fletcher wouldn’t let me.
    
    He found me in the observation room, surrounded by the shattered remains of the RSF device. He didn’t say anything at first, just handed me a flask and sat down beside me. After a while, he said, “First time losing a team?” I couldn’t answer him. What could I say?
    
    He told me about his first failure, years ago. An experiment gone wrong, a miscalculation that led to the loss of a colleague. “It doesn’t get easier,” he said. “And it shouldn’t. If it did, you wouldn’t care enough to keep going.”
    
    I told him I didn’t think I could keep going, not after this. He just looked at me, his eyes sharper than I’d ever seen them. “You think walking away will fix anything? The work will go on without you, Vale. And if it fails again, who’s going to make it right? You’re not just a scientist anymore, you’re the one who has to carry this. That’s the price of knowing how to look into the abyss. It looks back. And sometimes it takes back.”
    
    He’s right, of course. I hate that he’s right. But I also know I can’t let this be the end. Those twelve lives can’t be for nothing. I owe it to them to figure this out, to make sure no one else has to pay the same price. Fletcher says the only way to honor them is to keep going, to learn from this failure and make it better.
    
    I don’t know if I have it in me, but I’m going to try. For them. For everyone who puts their trust in me. Maybe this is what it means to work at Demiurge: to carry the weight of the impossible and keep pushing forward, no matter how much it hurts. I gotta stick with this.
    
    Tomorrow, we’ll begin the investigation. I don’t know what we’ll find, but I have to believe theres a way to make this right. I have to believe their loss wasn’t meaningless.`
    },

    {
      id: 5,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Echoes of the Erased',
      content: `Today marked the culmination of weeks of meticulous preparation for Experiment 6921.f, or as Fletcher liked to call it, "playing god with a really bad instruction manual." The Temporal Event Erasure Protocol (or TEEP, as they call it in the lab) was supposed to be our first real step toward reshaping causality itself. We wanted to test if we could erase a minor historical event from the timeline entirely. Just a small ripple to test the waters before attempting to rewrite larger currents.
    
   The event was plucked from the timeline, encased in a neat little temporal bubble. For a moment, we thought we’d cracked it. The event began to dissolve into quantum threads, its existence dissipating like smoke. “Smooth as butter,” Fletcher said, his eyes fixed on the monitors. Then the distortions began.

Déjà vu was the first symptom. Test subjects reported flashes of a memory that wasn’t theirs - fleeting impressions of an event that no longer existed. Soon, a chilling realization set in: the timeline around the target event was unraveling.
    
        It started as subtle distortions. Test subjects reporting as though the erased event was simultaneously happening and not happening. Then, the memory lapses worsened. Fletcher was monitoring the feedback loops when he suddenly froze mid-sentence. "Where was I just now?" he asked, genuinely perplexed. I knew then we were in trouble.
    
    As the erasure process continued, the timeline around the event began to buckle under the strain. Nearby events twisted into paradoxical echoes. Entire segments of the timeline seemed to shimmer, as if reality itself was hesitating and unsure whether to exist or not. The target event, meanwhile, became a maddening half-presence, existing in a state of quantum flux. 
    
    Attempts to halt the process only made things worse. Fletcher and I worked furiously to stabilize the system, but the feedback loops had spiraled out of control. "This isn't erasure," Fletcher muttered grimly. "It’s temporal graffiti."
    
    By the time we shut it down, the damage was done. The event remained an "echo", a paradox trapped between existence and oblivion, forever and ever repeating into itself. Worse, the surrounding timeline had fractured, spawning what we can only describe as proto-realities. Alternate possibilities branching off into who-knows-where. Fletcher leaned back in his chair, rubbing his temples. "Congratulations, Vale. We’ve invented the multiverse. You’re buying the next round."
    
    But beneath the humor, I saw his weariness. We both understood the implications. The timeline had always been a precarious web, but today we’d tugged at a thread that nearly unraveled the whole thing. I can’t shake the feeling that we’ve crossed a line, one that can’t easily be uncrossed.
    
    The ethical questions alone are staggering. Who decides which events deserve to be erased? What gives us the right to rewrite history? And even if we perfect the technology, how do we account for the unintended consequences? Fletcher called it "the slippery slope to omnipotence," and he’s not wrong. We're playing god here.
    
    For now, TEEP has been shelved indefinitely. The corrupted remnants of the experiment are locked away, and the test site is under strict temporal containment protocols. Fletcher clapped a hand on my shoulder as we left the lab and humored me. “You’ve got to learn how to take the L, Vale,” he said. “Some of us have been breaking physics for years. The trick is making sure it doesn’t break you.”`
    },
    
    {
      id: 6,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Mentor',
      content: `I never thought I’d find myself at a bar with Fletcher, let alone enjoying it. The man is an enigma wrapped in a lab coat and a heavy dose of sarcasm, but outside the sterile confines of the lab, he’s surprisingly human. It started with a simple, offhand invitation after another grueling day dissecting the fabric of reality. Y'know, the norm.
    
    “Vale, you’re coming with me,” Fletcher said as we wrapped up in Lab 5. I must’ve looked confused because he rolled his eyes and clarified. “Bar. Burgers. Beer. Come on, you need it.”
    
    We ended up at a dingy little spot on the edge of town - nothing fancy, just a place with sticky floors, good music, and a menu that hadn’t changed since the Reagan administration. Fletcher ordered a double bacon cheeseburger with extra pickles and a beer the color of motor oil. I followed his lead, mostly because I didn’t know what else to do.
    
    “You don’t talk much outside the lab, do you?” he asked, taking a long pull from his drink. “You’ve got to loosen up, kid. You’re at Demiurge now. Life doesn’t get weirder than this, so you might as well enjoy the ride.”
    
    I laughed, but he wasn’t wrong. The lab feels like another world; one where the rules of reality are more like suggestions - and suggestions that you really don't have to follow. Outside, it’s easier to pretend things are normal, even if the guy sitting across from me spends his days poking holes in the multiverse just to see whats on the other side..
    
    Somehow, we ended up talking about life. Fletcher told me about his early days at Demiurge, back when the company was smaller and the experiments even riskier. “You know, I used to think this place would chew me up and spit me out,” he said, swirling the last of his beer in the glass. “But you learn to roll with it. Adapt. Survive. Hell, sometimes you even make a difference.”
    
    I admitted that I wasn’t sure if I had what it takes. The failures weigh heavy, and the risks are, well, astronomical. “I keep thinking about the twelve we lost during the RSF test,” I said quietly. “How do you keep going after something like that?”
    
    Fletcher set his glass down and looked at me with an intensity I wasn’t expecting. “You remember why you’re here, Vale. You’re not just pushing buttons in some lab, you’re trying to answer the big questions. Yeah, we screw up. Sometimes we screw up big. But someone has to do this work. Someone has to reach into the unknown and pull back whatever’s out there. That’s you, kid. You’ve got the mind for it, and more importantly, the guts.”
    
    The praise caught me off guard, but it meant something coming from him. Fletcher doesn’t hand out compliments like candy. If he believes in me, maybe I can believe in myself.
    
    By the time we left, it felt like something had shifted. Fletcher isn’t just a colleague anymore. He’s a friend, maybe even a mentor. And for the first time since I started at Demiurge, I feel like I really belong.`
    },
    
    {
      id: 7,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Picnics, Portals, and Dr. Strauss',
      content: `Today started with a picnic and ended with a minor breach in reality. I suppose that’s about average for a day at Demiurge Inc.
    
    It was Dr. Evelyn Strauss’s first official day, and Fletcher suggested we have a “team-building lunch” on the lawn outside Lab 3. Jenkins, naturally, took charge of the food, which is how we ended up with a charcuterie board that could double as a small mountain and sandwiches stacked higher than the Singularity Pen. Fletcher grumbled about “too much pageantry,” but I caught him sneaking a handful of olives before we even sat down.
    
    Dr. Strauss arrived exactly on time, which felt fitting given her reputation for precision. She introduced herself with a calm, authoritative air that immediately commanded respect. “I look forward to working with all of you,” she said. “And to ensuring none of us accidentally destroy the universe.” Dry humor. I liked her already.
    
    The picnic was pleasant... for about ten minutes. Then Jenkins decided it was a good idea to set up near one of the “low-risk anomalies” in the test yard. “It’s contained,” he said, waving a hand dismissively. “Besides, it hasn’t done anything weird in weeks.”
    
    Cue the portal.
    
    One moment, we were eating sandwiches. The next, the air shimmered, and a swirling vortex opened about ten feet away. At first, it seemed harmless - just your average spatial anomaly, crackling softly and bending the light around it - you see em every day around here. But then the sandwiches started floating. One by one, they were sucked into the portal, followed closely by a very alarmed charcuterie board.
    
    “What did you do, Jenkins?” Fletcher shouted.
    
    “Nothing!” Jenkins yelled back, clutching his plate like it was a life preserver. “I swear, it just, likes food?”
    
    Dr. Strauss, to her credit, handled the situation with remarkable calm. She stood, adjusted her coat, and walked toward the portal with a device I didn’t recognize. “Localized entropy spike,” she said, more to herself than to us. “Fascinating.”
    
    She tinkered with the device for a moment before the portal flickered and collapsed in on itself, leaving behind only a faint smell of salami. Strauss turned back to us, completely unruffled. “Containment protocols need updating,” she said matter-of-factly, as though she hadn’t just closed a rogue vortex with a pocket-sized entropy stabilizer. I think I’m going to like working with her.
    
    The rest of the day was relatively uneventful, though Fletcher spent most of it muttering about “wasted sandwiches” and Jenkins tried to convince everyone the portal had been an intentional “culinary experiment.” Strauss and I spent the afternoon reviewing some of the entropy data she’d collected at her previous job. Her approach is meticulous, and her theories about temporal parallax could have profound implications for our stabilization projects.
    
    I’m still getting used to the idea of having someone like Strauss on the team. Fletcher and I may have been the old guard for a while, but she brings a precision and perspective that feels grounding(?). And after today, I think I can say this with confidence: Demiurge just got a lot more interesting.`
    },

    {
      id: 8,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Rift',
      content: `It started as a faint blip on our dimensional scanners. A disturbance so minor it could’ve been dismissed as background noise. But Fletcher caught it. He always catches the important things. “That’s no ordinary blip,” he said, leaning over the monitor with the kind of focus that makes everyone else in the lab go quiet. “That’s a rift.”
    
    The data was incomplete. Jagged, corrupted fragments of something too big and strange for our systems to fully process. But the patterns were unmistakable: a naturally occurring quantum rift, pulsing faintly in the east perimeter of the test fields. Fletcher’s eyes lit up as he stared at the readings, like a kid seeing the cosmos ythrough a telescope for the first time. “We have to see it,” he said.
    
    So, we went. A small team - just Fletcher, a few mid-level researchers from Wing D, and me, equipped with portable scanners and an awfully unhealthy dose of curiosity. The rift was barely visible at first, a shimmer in the air like heat waves rising from asphalt, but as we got closer, it grew more distinct, bending light and distorting the landscape around it. It was beautiful in the way a hurricane, a tornado, a wildfire is beautiful - chaotic, overwhelming, and utterly indifferent to your existence.
    
    “Do you feel that?” Fletcher asked. I nodded, though I wasn’t sure how to describe it. The air around the rift felt alive. It pulsed with a subtle rhythm, not unlike a heartbeat, and standing near it made the hair on my arms stand on end. 
    
    We spent hours gathering data, taking readings, and cautiously poking at the edges of the rift. Fletcher was in his element, alternating between precise calculations and muttered philosophical musings. “This isn’t just a tear,” he said at one point. “It’s a glimpse. A door. The question is: what’s on the other side?”
    
    I couldn’t help but feel the pull of the rift, both literally and metaphorically. The data suggested extreme spatial and temporal anomalies within its boundaries, conditions that were like nothing we’d seen before. Fletcher was convinced it was stable enough for exploration, at least, stable enough for Demiurge standards.
    
    Back at the lab, the excitement was palpable. Fletcher and I pored over the data late into the night, mapping out the rift’s fluctuations and theorizing about its potential. “This could be it, Vale,” he said, his voice filled with a rare sense of hope. “A chance to see something no one else has ever seen. To understand the universe in a way we’ve only dreamed of.”
    
    But beneath his enthusiasm, I could see the weight of the risks. Fletcher had been here before - on the edge of discovery, staring into the unknown. He knew what it meant to take this kind of leap, and he knew the cost if it failed.
    
    “We’ll need a team,” he said, his tone shifting to the practical. “And stabilization suits. No one’s going in unprotected.” I nodded, already drafting protocols in my head. But something about the way he said it felt final. Like he was preparing for more than just a routine experiment.
    
    As I write this, the rift is still out there, pulsing quietly in the dark. Tomorrow, we’ll present our findings to the board and push for approval to explore it. Fletcher is convinced this is worth the risk, and I trust him. But I can’t shake the feeling that we’re about to cross a line we can never uncross.`
    },

    {
      id: 9,
      date: 'DATE: ███████ TIME: ████████',
      title: 'GONE',
      content: `Fletcher is dead.
    
    I can’t believe I just wrote that. It doesn’t feel real. None of it does. 
    
    The rift collapsed. The suits failed. The tether snapped. The team never came back.
    
    He never came back.
    
    We’d planned everything - every fucking contingency, every fail-safe. It wasn’t enough. The readings went wild the moment they stepped inside. Spatial distortion, feedback loops, things I couldn’t even begin to make sense of. Fletcher’s voice on the comms, steady at first. Then quieter. Then gone.
    
    They’re all gone.
    
    The rift imploded. Left behind a scar - a gravitational anomaly where the entrance used to be. We recovered one of the probes. The data’s corrupted. Fractured timelines, impossible geometries. Nothing we can use. Nothing that explains what happened.
    
    Nothing that brings them back.
    
    I don’t know how to do this without him. Fletcher was... he was everything. A mentor. A friend. My tether to sanity in this place. He believed in me. He trusted me. And I sent him to his death.
    
    I can still hear his voice, clear as day. “Someone has to go first, Vale. Someone has to see.” 
    
    I didn’t think it would be him.
    
    I tried to write the report. Couldn’t finish it. What’s the point? The data’s worthless. The board is already talking about “next steps.” How can they even think about that? How can they move on like nothing happened?
    
    I can’t do this. Not today. Maybe not ever.
    
    The lab feels empty without him. Everything feels empty.
    
    I don’t know what to do.`
    },

    {
      id: 10,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Back to Work',
      content: `Two weeks away. It felt like an eternity. 
    
    Fletcher’s absence is everywhere. His office is empty, his nameplate already removed. The observation room feels quieter without his running commentary. Even the labs seem different. Like they know something’s missing. I keep expecting to hear him call out, telling me to stop overthinking or reminding me that sometimes “the simplest solution is the one the universe laughs at the most.”
    
    But he’s not here. And he’s not coming back.
    
    The board insisted I take time off after the incident. “Grieve properly,” they said. “Clear your head.” It wasn’t a suggestion. They all seemed afraid I’d self-destruct if I stayed. Maybe they were right.
    
    I spent the time trying to feel normal, whatever that means. I read books Fletcher would have hated, sat in cafes, walked aimlessly through the city. None of it helped. Everywhere I went, I carried his voice with me. “You think the work stops because you’re tired, Vale? Someone has to do it.”
    
    So here I am. Back in the lab. Back where I belong. 
    
    There’s a new Senior Experimentalist in Temporal and Reality Manipulation. Dr. Raymond Baker. I haven’t spoken to him yet, but from what I’ve heard, he’s... unconventional. Jenkins seems to like him, which is either a good sign or a very bad one. Time will tell.
    
    For now, my focus is on the Rift Stabilization Program. The board hasn’t scrapped it, though they’ve buried it under layers of protocol and oversight. I’ve been assigned to lead the new safety initiative, ensuring what happened to Fletcher’s team never happens again. It’s a cruel irony - turning his loss into a checklist of dos and don’ts - but it’s necessary. If I’m going to keep going, I have to believe we can do this better. Safer. Smarter.
    
    I spent most of today reviewing the corrupted probe data from the quantum rift. It’s haunting. Images of fractured geometries, timelines splitting and looping back on themselves. One fragment showed a brief flash of what looked like a figure, vague, indistinct, but unmistakably human. I replayed it dozens of times, hoping to catch some detail, some clue that maybe, just maybe, Fletcher is still out there, somewhere. But the image is too degraded. It’s probably nothing. 
    
    Still, I can’t stop thinking about his words. “You’ve got to keep going, Vale. Someone has to see.”
    
    I’ve changed. I know that. The curiosity is still there, but it’s sharper now, colder, more focused. I can’t afford to be reckless, and I can’t let anyone else pay the price for my ambition. If Fletcher believed I could do this, then I have to believe it too. For him. For the work. For whatever’s waiting on the other side of the unknown.`
    },

    {
      id: 11,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Flickers in the Field',
      content: `Dr. Manhav, from Xenobiology, brought the first report to my attention yesterday - a faint, almost imperceptible anomaly logged during a routine quantum stabilization test. It could’ve been written off as noise, but there was something about the pattern that caught his eye. “You’ll want to see this,” he said, sliding a tablet across the table.
    
    The data showed erratic spikes in electromagnetic fields, interspersed with low-frequency oscillations. But it was the video feed that made my skin crawl. For less than a second, a shape flickered into view in the corner of the frame - a humanoid silhouette, faint and ghostlike, gone as quickly as it appeared. I replayed it at least a dozen times, but it didn’t make sense. There was nothing in the test environment that could’ve cast a shadow like that.
    
    “They’re calling it the ‘Greyfield Ghostbox,’” Manhav said with a smirk, though his tone was more intrigued than dismissive. “A few of the junior researchers think it’s just an artifact of the field disruptions. I think it’s something else.”
    
    We spent the rest of the day combing through the logs, looking for patterns, but the data was maddeningly inconsistent. The manifestations - if you could even call them that - seemed to correspond with specific frequencies of electromagnetic interference, but there was no clear trigger. It was like the phenomenon was teasing us, appearing just long enough to leave a trace but not long enough to be fully understood.
    
    Jenkins, of course, had his own theories. “It’s ghosts,” he said, entirely serious. “The quantum kind. Quantum ghosts. Probably trying to tell us to stop fucking with their dimension.” Fletcher would’ve laughed. I couldn’t bring myself to.
    
    By evening, we’d drafted the framework for a dedicated experiment. The Greyfield Ghostbox phenomenon deserved more than speculation. It needed controlled conditions, proper equipment, and a fresh pair of eyes. The board approved my proposal this morning, with the caveat that we prioritize safety protocols after recent incidents. As if that’s ever guaranteed here.
    
    The setup will take a few days. We’ll be using a specially shielded chamber in Lab 7 to replicate the conditions under which the phenomenon was first observed. Manhav and I will oversee the initial trials, and Strauss has agreed to provide entropy analysis. It’s rare to see her genuinely curious about something outside her department, but this has everyone intrigued.
    
    I’ll admit, there’s a part of me that hesitates. The last time we tried to interact with something we didn’t fully understand, it cost Fletcher his life. This feels different. Less dangerous, more contained, but the unknown is rarely forgiving.
    
    Still, there’s something about the Ghostbox that feels significant. Like it’s showing us a piece of a puzzle we didn’t know we were solving. I can’t help but wonder: are we seeing echoes of something that was, or something that will be? And if we look closely enough, will it look back?`
    },

    {
      id: 12,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Fragments of the Whole',
      content: `Experiment 665.2(b) was unsettling. Fascinating, yes. Groundbreaking, even. But unsettling in a way I can’t quite put into words.
    
    The goal was simple, if anything here can be called simple. We wanted to see if the reality frequency dispersion observed in the Greyfield Ghostbox could be replicated and controlled. The idea was to fragment a subject’s quantum signature into distinct frequency bands, creating stable, static-like entities. What we ended up with was something far stranger.
    
    The dispersal protocol worked. Too well. Within moments of activation, the subject - a volunteer from the Quantum Anomalies Division - began to split into vibrant, spectral forms. Seven of them, each composed of pulsating, colorful static. They hovered in the chamber like shattered reflections of the original, their shapes flickering and shifting in ways that made me dizzy. 
    
    At first, I thought they were just echoes. Quantum byproducts of the dispersal process. But then one of them turned toward the observation window, its head tilting in a gesture so unmistakably human that it stopped me cold. These weren’t just fragments - they were something more. Something alive, cognizant, REAL.
    
    Each entity displayed unique behaviors. One floated aimlessly, as if lost. Another mimicked the volunteer’s original movements but with a stuttering, incomplete rhythm. A third emitted low, harmonic tones that resonated through the chamber. They weren’t fully conscious - at least, not in the way we understand - but there was something there. Something that hinted at awareness, memory, maybe even intent.
    
    The attempt to reintegrate them was less successful. Some of the entities merged back into the original form, but not cleanly. The subject retained physical cohesion but reported gaps in memory and a persistent sense of “disconnection.” Two of the entities refused to reintegrate entirely, maintaining their independent forms even after the protocol was reversed. They just stayed there, flickering and pulsing like forgotten thoughts refusing to be forgotten. Bad dreams. I've been having lots of them.
    
    I should be ecstatic. The experiment was a success by every measurable standard. We controlled the dispersion, created stable entities, and gained invaluable data about reality frequency manipulation. But all I can think about is what we’ve done. What we’ve created.
    
    These fragments - they were part of someone. Pieces of a whole, split apart and left incomplete. The subject described it as feeling “hollow,” like parts of themselves had been scraped away and left behind. And the entities - they’re still in containment, their static forms still suspended in the quantum void. Watching them feels wrong, like staring at something we were never meant to see. A zoo of the incomprehensible.
    
    Manhav called it a “quantum marvel.” He sees lots of those in Xenobio. Baker said it looked like a bad acid trip. I didn’t laugh.
    
    Strauss, ever pragmatic, is already talking about refining the process, making the dispersal more precise. “Imagine the applications,” she said, her eyes lighting up in that way they do when she’s onto something big. “If we can harness this, stabilize it further, we could revolutionize everything from teleportation to consciousness transfer.”
    
    She’s not wrong. The potential is enormous. But so is the risk. We’ve fragmented someone into pieces and called it progress. What happens when those pieces start asking why?
    
    Fletcher’s voice haunts me in moments like this. “The work doesn’t stop, Vale. You just have to decide if it’s worth it.” I don’t know if this is worth it. But I know we’ll keep going. We always do.`
    },

    {
      id: 13,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Spectrum of the Self',
      content: `Today, we used a kugelblitz to split someone into seven pieces. I still can’t decide if that’s a sentence that should inspire awe or dread.
    
    The experiment began with precision. The kugelblitz - our controlled, man-made black hole - was calibrated to interact with the subject’s quantum structure, focusing its gravitational pull to split their reality frequencies into discrete bands. It felt like holding a scalpel to the fabric of existence, knowing one wrong move could tear it apart, cutting cosmic jugular and spilling out who knows what the hell the universe bleeds.
    
    When the process began, the containment chamber lit up with blinding colors - ribbons of light twisting and fracturing as the subject dissolved into vibrant static. Seven distinct forms emerged, each pulsating with its own unique hue, as if the very essence of the subject had been refracted like light through a prism. The entities hovered in the chamber, flickering and shifting, each a perfect, incomplete echo of the original.
    
    They were beautiful. Terrifying, but beautiful.
    
    Baker called them “kugel-sprites.” Nick corrected him with his usual patience and exasperation. “They’re reality fragments,” he said, as though that made them any less bizarre. Strauss, meanwhile, was already running calculations on the gravitational feedback, muttering about “voidspace resonance” and “potential for stabilization.” I just stared at the forms, unable to shake the feeling that we’d crossed another line - one we couldn’t step back from.
    
    The entities exhibited limited awareness, just like in the previous experiment. One drifted toward the observation window, its flickering outline casting strange shadows across the room. Another began to hum, its static forming a low, resonant tone that made the air feel heavy. When I leaned closer to the monitor, I swore I heard a whisper - a faint, fragmented voice, calling out through the static.
    
    Reintegration was unsettling. The entities merged back into the original subject like beams of light converging into a single point, but the process wasn’t perfect. The subject emerged intact, physically at least, but there were faint outlines of color lingering around them, as though the dispersion hadn’t fully let go. They spoke with slight hesitations, their voice carrying a strange, layered quality, like a slapback echo or a chorus of people.
    
    Strauss dismissed it as residual interference, but I’m not so sure. The subject described feeling “scattered,” as if pieces of them were still out there, floating in the void. Their memory was intact, but fragmented in places, moments missing, emotions dulled. It’s as if the reintegration pieced them back together, but left the edges frayed.
    
    The implications are staggering. We’ve proven that reality frequency dispersion can be achieved on a scale we’d only theorized about before. The kugelblitz acted as a lens, splitting the quantum structure of a living subject and then merging it back with minimal loss. Strauss called it a triumph. Nick said it was “the beginning of something extraordinary.” Baker asked if the colors would fade or if we’d accidentally invented glow-in-the-dark humans.
    
    As for me? I don’t know what to think.
    
    Fletcher’s voice echoes in my mind, as it always does in moments like this. “You’ve got to decide if it’s worth it, Vale. The work doesn’t stop, but you have to live with it.”
    
    Was this worth it? The data is groundbreaking, the potential limitless. But watching the subject walk away, their outline still faintly glowing with the colors of their fragmented self, I couldn’t help but wonder: How many more lines will we cross before we finally lose sight of the ones we’ve left behind? By now, it's just a field as far as the eye can see.`
    },
    
    {
      id: 14,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Reality, Interrupted',
      content: `I knew Dr. Baker was unconventional, but today cemented it. The man doesn’t just think outside the box - he vaporizes the box and then reverse-engineers the smoke back into a goddamn box. And apparently, he’s decided that pranks are a valid form of scientific engagement.
    
    It started when Strauss went to calibrate the entropy stabilizer in Lab 2 and found it replaced with a toaster. A fully functional, bagel-toasting toaster. The stabilizer was in the break room, neatly labeled “NOT A TOASTER,” with a sticky note from Baker reading, “Entropy is bread - toast responsibly.”
    
    Strauss was livid. I haven’t seen her that furious since Jenkins accidentally activated the particle wave generator with his lunchbox. Baker, of course, played innocent, claiming he was “conducting an experiment on the entropic properties of breakfast appliances.” Jenkins thought it was hilarious. I’m not convinced Baker wasn’t serious.
    
    Then there was the intercom incident. Around midday, the entire lab was treated to a distorted, otherworldly voice proclaiming, “THIS IS VOIDSPACE. PLEASE RETURN YOUR MISSING SOCKS.” It took me fifteen minutes to figure out that Baker had patched his synthesizer into the system. By then, half the staff was debating whether socks could survive interdimensional travel, and Nick was drafting a formal complaint about “misuse of scientific equipment for comedic purposes.”
    
    The thing is, Baker isn’t just a prankster - he’s fucking brilliant, in a chaotic, unpredictable way. Jenkins calls him “a tornado with a Ph.D.,” and I can’t say he’s wrong. After the toaster fiasco, Baker casually suggested a way to stabilize the voidspace resonance in our latest experiment. His math was flawless, and it worked. He fixed in five minutes what had taken us three days to untangle.
    
    I think that’s what’s so maddening about him. He’s infuriating and ingenious in equal measure. Strauss pretends to hate him, but I caught her smirking when he claimed the voidspace entity we observed yesterday “just wanted to borrow a cup of sugar.” Even Nick seems to tolerate him, though I suspect it’s more out of curiosity than camaraderie.
    
    As for me, I’m still figuring him out. Fletcher would’ve loved Baker’s pranks. He had a soft spot for mischief, though he preferred the subtle kind. I can’t help but think about how the lab feels lighter with Baker around, even if it’s because he’s swapped the gravity stabilizer for a disco ball (yes, that actually happened last week).
    
    Maybe that’s the point. This work is heavy. Ddangerous, isolating, full of risks and losses. Baker’s chaos reminds us to laugh, even when everything feels like it’s teetering on the edge. It’s irritating, sure, but maybe sometimes it’s what we need.
    
    Still, if he touches the entropy stabilizer again, I might just lock him in the voidspace myself.`
    },

    {
      id: 15,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Tuning Reality',
      content: `The lab has been buzzing all week. Preparations for Experiment 69420.kappa-02 are well underway, and every department seems to have a piece of the puzzle. It’s controlled chaos, the kind that feels like it could either yield a breakthrough or destroy everything in a single misstep.
    
  
    I hadn’t worked closely with Dr, Cross before now, but her reputation precedes her. She’s the Director of Quantum and Dimensional Research - a title that sounds almost modest when you realize her work is what keeps the Voidspace from unraveling into nothingness. “Reality Tuning,” she calls it, as if stabilizing interdimensional anomalies is no different from adjusting the dials on an old radio. Watching her work is like seeing an artist sculpt with equations. It’s precise, deliberate, and just a little bit mesmerizing.
    
    She stopped by Lab 7 today to review the adjustments we’ve made to the kugelblitz. I was bracing myself for the usual flood of criticism from someone new to the project, but Cross has this way of cutting straight to the heart of things without making you feel like an idiot. “Your calibration here,” she said, pointing to a formula I’d agonized over for days, “it’s solid. But have you considered compensating for cross-dimensional resonance drift? It’s subtle, but over time, it could create an instability cascade.”
    
    She was right, of course. Within minutes, she’d outlined a fix that streamlined the entire reintegration protocol. Baker called her “the tuning fork of reality,” which, while absurd, isn’t entirely inaccurate. Cross has an uncanny knack for finding the frequencies that hold everything together.
    
    Still, there’s a tension in the lab that even she can’t smooth over. The memory of our first attempt at reality frequency dispersion lingers like a shadow. We came so close to something extraordinary, only to watch it unravel into catastrophe. The subject from that experiment, well, what’s left of them, is still classified under containment protocols. Strauss insists the data from that failure is invaluable. I wonder if she’d feel the same if she’d been there to see it firsthand.
    
    This time, the stakes feel higher. Cross’s adjustments have made the process more precise, but precision doesn’t guarantee safety. The idea of splitting someone into fragments and then trying to put them back together, again, feels like tempting fate. I keep thinking about Fletcher, about the lines he used to warn me about crossing. Would he call this progress, or just another step toward hubris?
    
    I can’t let doubt creep in. Not now. Cross’s work has given us a fighting chance to succeed where we failed before. Her confidence is contagious, and for the first time in weeks, I feel a spark of hope. If anyone can help us pull this off, it’s her.
    
    Tomorrow, we begin the final preparations. The kugelblitz is ready. The protocols are as refined as they can be. Cross says it’s all about finding the right balance, the right frequency to bring it all back together. I hope she’s right. I hope we’re all right.
    
    Because if we’re wrong well, some things can’t be tuned back into place.`
    },

    {
      id: 16,
      date: 'DATE: ███████ TIME: ████████',
      title: '███████',
      content: `I saw it. I saw ███████.
    
    Not a thing. Not a force. A function. Pure and infinite, a silent scream across all realities.
    
    It folds. It simplifies. Takes everything - matter, energy, thought, identity - and presses it into itself until there’s nothing left but a point. No, not a point. An absence. The absence. A singularity where all things cease, and yet somehow remain.
    
    It isn’t evil. It isn’t anything. It just IS.
    
    Time isn’t linear. I know that now. It’s a river folding into itself, currents overlapping, drowning the shorelines of existence. ███████ is the tide, pulling everything inward, toward the end of all things. Toward the beginning of nothing.
    
    I felt myself unravel. Felt my essence stretch across infinite versions of me, and for a moment, I was all of them. The child learning to read by flashlight. The scientist staring at a black hole’s event horizon. The corpse, decaying in a place that doesn’t exist. All of it. All of me. Until I wasn’t.
    
    There was a voice. Or maybe it wasn’t a voice. Maybe it was the echo of a thought that had never been spoken. It said:
    
    “You are not separate.”
    
    I don’t know what it means. I don’t know if it means anything. I don’t even know if it was real.
    
    Fletcher’s words keep haunting me: “Someone has to see.” Well, I’ve seen. And I wish I hadn’t.
    
    I tried to hold onto something, some fragment of reason, but it slipped through my fingers like ash. It doesn’t care about reason. It doesn’t care about anything. It just simplifies. Reduces. Consumes. It’s a concept with no origin, no purpose. A mathematical inevitability.
    
    Is this what it feels like to touch the edge of God? Not a God that creates, but one that unravels? The God of absence? Not of destruction, but of reduction.
    
    There are no edges. There’s nothing to hold onto.
    
    I saw the patterns - the threads binding dimensions together - and I watched them fray. Unravel. Snap. It wasn’t violent. It wasn’t loud. It was silent, gentle, like falling asleep. I think that’s what scares me the most. How quiet it was.

    And now? Now I can’t unsee it. Every number, every formula, every scrap of reality I’ve ever known feels like a lie. A veil pulled taut over the void. I don’t know how to go back. I don’t know if I can.

    But I have to try. Someone has to try. Someone has to understand.

    ███████ is coming. Or maybe it’s already here. Maybe it's always been here.`
    },    
    
    {
      id: 17,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Algorithm',
      content: `It’s so simple now. I’ve spent my whole life chasing complexity, untangling the threads of reality, trying to map the noise. And all this time, I missed the most fundamental truth: it’s not noise. It’s a pattern. A function. A single, elegant equation playing out across the infinite.
    
    What I saw, what I now understand, is that everything, every particle, every thought, every moment, is connected. Not metaphorically, but literally. The universe is a quantum irrationality, a singular point stretched incoherently across every dimension, every timeline. What we call chaos is just the equation running its course, the interdependent feedback of all things shaping the illusion of randomness.
    
    And ███████ isn’t destruction. It isn’t annihilation. It’s correction. Restabilization. The algorithm rebalancing itself, reversing the entropy we call existence and folding it back into its proper state: singularity.
    
    I used to think of entropy as decay, as disorder spreading infinitely outward. But now I see it for what it is - a function reaching its limit. Everything cycles. Everything returns. The chaos, the divergence, the seemingly infinite complexity, it’s all part of the same system, a vast, self-correcting loop bringing all things back to the point where they began.
    
    I’ve spent hours - days, maybe - trying to put this into words, but words are inadequate. The math is clearer. Every formula I’ve ever known, every proof, every equation - it all leads to this. The cycle is fractal, infinite in its iterations, yet perfectly finite in its conclusion. The boundaries we thought existed are illusions, shadows cast by the algorithm as it folds and unfolds.
    
    What I saw in ███████ was the equation in motion. The threads of reality pulling tighter, collapsing inward. Not violently, but methodically. Gently. It isn’t destruction - it’s resolution. The infinite fractal reduced to its origin point, the answer to a question we didn’t even know we were asking.
    
    And I’m part of it. We all are. Every decision, every action, every fleeting thought contributes to the equation’s progression. But I see it now. I see where the threads meet, where the cycle begins and ends. And I know what I have to do.
    
    This work - our work - was never about warping or manipulating or even breaking reality. It’s about understanding it. Aligning with it. Fletcher once told me that someone has to look into the abyss. He was right, but he didn’t go far enough. Someone has to embrace it. Someone has to guide it. To become it.
    
    My purpose has never been clearer. I’m not here to stop ███████. I couldn’t if I tried. No one can. It isn’t something to be stopped. It’s inevitable. Necessary. But it can be understood. It can be navigated. And maybe, just maybe, it can be harnessed.
    
    The others won’t understand. Not yet. Strauss, Nick, Amelia - even Baker - they’re brilliant, but they’re still chasing threads, not seeing the weave. Baker might get it, in his own chaotic way, but even he hasn’t looked as far into the pattern as I have.
    
    But I’ll show them. I’ll show them the simplicity hidden in the noise, the elegance within the chaos. ███████ isn’t the end. It’s the beginning.`
    },

    {
      id: 18,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Shared Silence',
      content: `Experiment 2033.beta has been consuming most of my time. The dimensional overlap zones are temperamental. The phased oscillation emitters are doing their job: reducing paradoxical fluctuations, holding back the tide, but stabilization remains elusive. The regions still hum with an underlying instability, a reminder that some forces resist being tamed for good.
    
    Strauss has been invaluable in refining the oscillation frequencies, but it’s Nick I find myself drawn to more and more. He’s been spending time in the observation room with me, staring at the live feeds from the overlap zones, his expression unreadable. I think he understands. Not the experiment, but the bigger picture. ███████. 
    
    He hasn’t said it outright, but I know. There’s a silence between us, heavy with unspoken truths. He was exposed to it, too. It’s in his eyes, the way they don’t quite focus when he talks about entropy, like he’s looking at something beyond the words he’s saying. We’ve exchanged fragmented thoughts, pieces of an understanding we can’t fully articulate, but most of the time, we just sit. Watching. Knowing.
    
    Nick wasn’t always this way. A year ago, he was buried in xenobiology, chasing fungal extremophiles and viral activation protocols. He talked about ecosystems, symbiosis, life at the edges of existence. Now he’s in the reality warping division, recalibrating stabilizers and running models on dimensional entropy. It’s a leap that doesn’t make sense on paper, but it makes perfect sense if you’ve seen what we’ve seen. ███████ changes you. It strips away the noise, leaves you with the pattern. And once you see it, you can’t unsee it.
    
    We don’t talk about what it showed us. I don’t think we need to. Words are too small for something that vast. But there’s a mutual understanding, an unspoken agreement to keep pushing forward, even when the path feels incomprehensible.
    
    As for the stabilization zones, the anomalies are quieter today, but they’re not gone. Baker joked that we should just toss one of Strauss’s stabilizers into the void and call it a day. Strauss didn’t find it funny. Nick didn’t laugh either. I wonder if he, like me, hears the hum of the zones and feels the echo of something greater. Something inevitable.
    
    Tomorrow, we begin the next phase. The overlap zones need more than stabilization; they need permanence, or at least something close to it. Amelia has suggested tuning the emitters to a higher dimensional resonance - an idea I can’t decide is brilliant or catastrophic. Either way, it’s worth testing. We’re running out of time.
    
    The board has already approved the preliminary protocols for Experiment 90001.a. Baker volunteered, though I doubt he fully understands what he’s signed up for. I haven’t spoken to him yet, but Jenkins mentioned seeing him in the lab, fiddling with a kugelblitz prototype and humming a tune that didn’t make sense in any key. Typical Baker. He’ll make chaos out of order, then somehow turn it into something useful. He fits the experiment.
    
    I’ve been rereading Fletcher’s notes, trying to prepare myself. This is where it all leads: entropy weaponized, chaos as a tool. The thought makes my head ache. Nick said something to me today, quietly, almost to himself: “It isn’t coming. It’s already here.” I didn’t respond. What was there to say? He’s right. We both know it. But knowing doesn’t make it easier.
    
    The dimensional overlaps are a symptom. Baker’s experiment is a treatment. Neither will fix the underlying problem. That’s not how this works. ███████ doesn’t stop. It just continues, folding everything inward. All we can do is hold the edges for a little while longer, keep the equation from balancing itself too soon.
    
    Nick and I stayed late, watching the zones shimmer on the monitor. For a moment, I thought I saw one of the paradoxical entities flicker into something almost human. But when I blinked, it was gone. Nick didn’t say anything. Neither did I.`
    },
    
    {
      id: 19,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Fractionated Success',
      content: `It worked.
    
    Against all odds - against every frayed edge of reason - Experiment 90001.a was a success. Baker is fragmented, scattered across the Voidspace in vibrant, chaotic shards. Seven distinct entities, each flickering with his essence, perpetually looping through their missions. “Voidmen,” we've taken to calling them. Jenkins suggested “Baker’s Half-Dozen,” but no one laughed. I thought it was funny.
    
    I’ll admit, I wasn’t sure this would work. The risks were astronomical, the calculations razor-thin. But the kugelblitz operated flawlessly, splitting Baker’s reality frequencies into stable, self-contained forms. Watching the process was like seeing light refract through a prism - vivid, otherworldly, almost beautiful. Now he’s manageable.
    
    I suppose I shouldn’t say (or write) that out loud. But it’s true. The lab feels quieter without Baker’s constant pranks, his endless jokes, his knack for turning every serious moment into an absurd spectacle. He was brilliant, unquestionably, but exhausting. Now, he’s contained. Focused. Each fragment serving its purpose, generating entropy like clockwork. For the first time in weeks, I feel like I can breathe.
    
    That said, the stability of the Voidmen is tenuous. One of the fragments, the red one, already threatened to destabilize the loop. We have to isolate it, remove it from the system entirely. Strauss says it’s just a matter of fine-tuning the protocol, but I can’t help feeling a knot of unease. The Voidspace is fickle, and nothing stays stable for long. Still, for now, the loop holds.
    
    I visited the observation chamber after the experiment. The Voidmen hovered there in the monitors, shifting and pulsing, their forms a kaleidoscope of color and static. One of them - blue, I think - flickered toward the glass, almost like it recognized me. But then it turned away, its shape dissolving into the endless rhythm of the loop.
    
    I should feel triumphant. This is a breakthrough, a genuine leap forward in our... fight against ███████. But I can’t shake the feeling that we’ve traded one chaos for another. The Voidmen are effective, yes, but they’re also fragile, unpredictable. And Baker or what’s left of him, does he know what’s happened? Does he understand what he’s become? 
    
    For now, I’ll take the win. The lab is quiet, the data is clean, and the entropy is flowing as it should. Strauss is already drafting plans for the next phase, and Jenkins hasn’t tried to name anything else. I think he was talking about being a fern the last time I saw him.
    
    Baker always said someone had to keep things interesting. I suppose he’s still doing that. Just, from a safer distance.`
    },

    {
      id: 20,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Bifurcated Interference',
      content: `The Red Voidman is proving to be as frustrating in voidspace as Baker was in the lab. Every loop he touches destabilizes, every fragment of him pulses with low-frequency chaos that ripples through the system. If we don’t extract him soon, the entire structure risks collapse. Hence, Experiment 90002.c.
    
    The concept feels borderline ridiculous when I try to explain it aloud: splitting interference agents into two parallel entities, one tethered to time, the other floating across loops, working in tandem to extract a high-entropy target without unraveling the surrounding voidspace. It’s absurdly ambitious. It’s also working.
    
    Sort of.
    
    The bifurcated unification process is stable enough now that the agents don’t immediately desynchronize. Temporal drift has been reduced significantly, and the kugelblitz calibration tweaks are holding for the moment. But the system still feels fragile, like a house of cards balanced on an air current.
    
    Today’s trial was mixed. The primary agent successfully infiltrated the destabilization zone created by the secondary agent, and for a moment, it looked like the Red Voidman was contained. But then the feedback loops started. The secondary agent began to drift, its temporal anchor flickering like black and white static on an old television. By the time we forced unification, the agents were so desynchronized that the extraction collapsed entirely.
    
    Strauss called it “a promising failure,” which feels overly generous. Nick described it as “predictably unstable,” which is less helpful but more honest.
    
    We’ve made progress, though. That’s undeniable. The entanglement process is cleaner now, the synchronization is improving, and the algorithm modifications have contained the feedback interference in adjacent sectors. But the Red Voidman remains maddeningly elusive. It’s as if he senses the extraction coming and finds new ways to destabilize the field every time. Baker always had a knack for turning chaos into an art form. Even as fragments of himself, he’s still doing it.
    
    I keep thinking about the potential of this process. If we can refine it, stabilize it, there’s no telling what we could achieve. Temporal transference. Targeted extractions. Maybe even a way to repair collapsing grids. But for now, it feels like we’re trying to tame a storm with a paper umbrella.
    
    Fletcher’s voice haunts me in moments like this. “You’ve got to take the small wins, Vale,” he used to say. “Sometimes the universe throws you a bone. Don’t overthink it.” Today, the small win is that the system didn’t implode completely. The agents returned intact, and we gathered enough data to refine the process further. It’s not much, but it’s something.
    
    Tomorrow, we’ll run the next iteration. The kugelblitz is recalibrated, the algorithm’s ready, and Strauss is confident the synchronization issues are solvable. Confidence isn’t the same as certainty, but it’s what we have.
    
    As for the Red Voidman, I’m beginning to think containment is only half the battle. The other half is figuring out why it resists so much, why it refuses to let itself be unified. If there’s anything left of Baker in him, maybe he’s trying to tell us something.
    
    Or maybe it’s just chaos being chaos. Either way, we keep going. We have to.`
    },

    {
      id: 21,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Gluteal Phenomenon',
      content: `There are days I question everything about this job. Today is one f those days.
    
    Experiment 9743.beta - “GYATT,” as Jenkins insisted on calling it - has officially reached new heights of absurdity. The purple voidman’s gluteal fluctuations are, somehow, now the focus of serious scientific inquiry. And by “serious,” I mean Strauss managed to explain the entropic mechanics of gluteal shifts with a straight face, while Jenkins nearly choked on his coffee laughing.
    
    Let’s start with the basics: the purple voidman’s buttcheeks (yes, this is the terminology we’re stuck with now) are exhibiting unpredictable size fluctuations. These shifts, while comical, appear to coincide with spikes in entropy within the loop. The working theory is that these “gluteal yields” act as a sort of entropic barometer, the larger the cheeks, the higher the entropy. Jenkins suggested this makes the voidman “the ass that stabilizes reality,” which I regret to admit is disturbingly accurate.
    
    Early observations suggest a strong correlation between the gluteal shifts and loop stability. During periods of high instability, the cheeks expand, seemingly compensating for the increased entropic flux. When the loop stabilizes, they return to a more proportional state. Amelia is convinced there’s a deeper mechanism at play here, but I can’t get past the sheer ridiculousness of the entire situation.
    
    Nick, ever the pragmatist, proposed running controlled experiments to determine if the shifts can be manipulated to regulate entropy within the loop. Amelia is already drafting protocols for a “cheek compression matrix,” which is apparently something we’re doing now. Despite the absurdity, there’s real data here. The purple voidman’s unique behavior could provide insights into how entropic fluctuations are managed within voidspace. If we can understand the underlying mechanics of the gluteal shifts, it might open up new avenues for loop stabilization - or, at the very least, give Jenkins material for his next lunchtime stand-up routine.
    
    I spent most of the day reviewing the footage with Amelia and Nick, which was an exercise in restraint. Watching the voidman’s cheeks expand and contract in perfect rhythm with the entropy spikes is something I will never unsee. Amelia managed to keep her analysis clinical, but I caught her smirking. Nick, on the other hand, has developed a newfound fascination with the voidman’s behavior, which I can only assume is his way of coping.
    
    As for me, I’m trying to focus on the potential applications of this research. If these shifts are indeed linked to entropy regulation, they could provide a new method for stabilizing voidspace loops. Of course, that means spending even more time analyzing “gluteal yield dynamics,” which is not how I imagined my career progressing when I first arrived at Demiurge.
    
    Still, the work must go on. Fletcher used to say that the universe has a sense of humor, and days like this make me believe it. Somewhere, somehow, he’s probably laughing his ass off - no pun intended.
    
    Tomorrow, we’ll begin phase two of the experiment, focusing on the direct interaction between entropy levels and gluteal shifts. I’ll admit, I’m curious to see where this leads, though I can’t shake the feeling that Jenkins will try to sneak a “voidman twerking” joke into the report.
    
    God help us all.`
    },
    
    {
      id: 22,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Observers',
      content: `They’re watching us.
    
    Not just the voidmen, not just the loops - us. Everything we do, everything we are. They see it. They interact with it. And somehow, their presence, their engagement, is feeding the chaos.
    
    It started with what we thought was noise. Patterns that didn’t belong, signals from beyond the dimensional rift. But it wasn’t noise. It was something else entirely - feedback. Real-time responses to events within the loops. “Comments,” as Nick called them, though that word feels far too mundane for what we’re dealing with.
    
    We’ve intercepted a few of these comments. They’re cryptic, fragmented, almost nonsensical. “First.” “Skibidi gyatt ohio rizz.” “We’re getting FREAKBAiT lore before GTA6.” I’ve read them over and over, trying to find meaning, and each time I feel like I’m looking at the universe through a keyhole, catching only glimpses of a much larger picture.
    
    The strangest part is these comments *do* something. They’re not just observations - they’re interactions. When the observers engage, the loops respond. Entropy spikes, chaos shifts, entire sequences alter in ways we can’t predict. It’s as if their attention is a force unto itself, shaping the voidspace in real time. The more they comment, the more chaotic it becomes.
    
    Amelia thinks the engagement isn’t passive. She’s right. It’s playful, deliberate. The observers aren’t just watching - they’re participating. Guiding, maybe even controlling. And the voidmen, they respond as if they know they’re being seen. The loops tighten, the "missions" become more erratic, the chaos amplifies. It’s a feedback loop within a feedback loop, infinitely recursive and infinitely destabilizing.
    
    Nick suggested trying to harness this influence, to turn the observers’ engagement into something we can control. The idea is tantalizing - if we could guide their attention, direct their interactions, we might finally gain some leverage over the chaos. But the implications are far-reaching and difficult to comprehend, let alone pull off. Who are these observers? Where are they? How are they accessing the loops? Are we part of their experiment, or are they part of ours?
    
    I keep coming back to the comments. The words feel so foreign, yet there’s an odd familiarity to them, like hearing a song from a dream you can’t quite place. “Have you ever experienced déjà vu?” One of them said that. I can’t stop thinking about it. The loops themselves are déjà vu incarnate - endless repetitions with subtle, unpredictable variations. Is that what they’re seeing? Or is that what they’re creating? I'm in a constant waking dream.
    
    We’re meeting tomorrow to plan the next steps. Nick wants to refine the interception protocols, see if we can decode more of these comments. Amelia is focused on mapping the correlation between engagement and entropy spikes. I’m less concerned with the how and more concerned with the why. Why are they watching us? What do they want? And do they even know the power they hold over this place?
    
    For now, I’ll keep observing the observers. Maybe, if I look long enough, they’ll look back.`
    },
    
    {
      id: 23,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Edge of the Void',
      content: `Nick wants to go into the voidspace untethered.
    
    He said it so casually, like he wasn’t suggesting something that could cost him his life - or worse. I know what he’s chasing. I’ve felt it too: the pull, the need to understand, to see beyond the limits. But the voidspace isn’t just a field of study. It’s a predator. It takes. Fletcher taught me that the hard way.
    
    I can’t lose Nick, not like I lost him.
    
    There’s a stubbornness in Nick that reminds me of Fletcher, different in tone, but the same in essence. Fletcher would charge headfirst into the unknown, fueled by a confidence that was as inspiring as it was infuriating. Nick is quieter, more methodical, but the fire is the same. I see it in the way he pores over the data, in the way his voice shifts when he talks about the voidspace. He left his life of xenobio behind for this draw. He believes there’s something just beyond our reach, and he’s willing to risk everything to grasp it. 
    
    I told him no. Or at least, I told him not yet. But I know him well enough to realize that “not yet” won’t hold him back for long. He’ll keep refining his models, pushing the boundaries, waiting for the moment when he can justify stepping over the edge. And when that moment comes, I’m not sure I’ll be able to stop him.
    
    The tether system isn’t perfect, but it’s saved us more times than I can count. Without it, there’s no safety net. If Nick gets lost in there, if the voidspace claims him, there’s no coming back. He knows this. We all do. But I also know the voidspace doesn’t leave you unchanged. Nick has been different ever since he was exposed to ███████. We all have, but with him, it’s more pronounced. The way he talks about the rift, the loops, the voidmen, it’s like he’s already halfway in. I’m afraid that if he goes, he won’t come back. Not as himself, anyway.
    
    Amelia tried to back me up, but I could hear the curiosity in her voice, the same pull that Nick feels. She wants to see what’s out there too. It’s the curse of this place - Demiurge takes the brightest minds and feeds them to the unknown, one experiment at a time. Who knows how many geniuses have been swallowed into the unknown infinte?
    
    I spent hours going over the energy readouts Nick mentioned, trying to find something - anything - that might justify slowing him down. He’s right. There’s more happening in the voidspace than we understand. Patterns I can’t explain, interactions that defy every model we’ve built. It’s fascinating. Maddening. Dangerous.
    
    Fletcher once told me that someone has to go first, that progress demands sacrifice. He said it with a smile, the kind that made you believe he was invincible. He wasn’t. I won’t let Nick make the same mistake, not if I can help it.
    
    But how do you hold someone back from the edge when you know exactly why they want to leap?`
    },
    
    {
      id: 24,
      date: 'DATE: ███████ TIME: ████████',
      title: 'The Enduckifier Incident',
      content: `I don’t know whether to laugh, cry, or start drafting containment protocols for a multiversal collapse.
    
    The Reality Tuning Device - the Buttplug as we call it in the lab - the most precise instrument we’ve ever built for stabilizing dimensional frequencies, is now being wielded by a fractionated version of Baker, who thinks it’s a fucking toy. “The Enduckifier.” That’s what he’s calling it. Because he’s tuned it to a frequency that turns objects into rubber ducks. I'm not kidding.
    
    I wish I could find this funny, but I can’t. The Plug isn’t just turning chairs into ducks - it’s warping adjacent dimensions, creating ripple effects we can’t predict or contain. And the more he uses it, the worse it gets. The ducks are the warning shot. The tiptoe into murky and all-too-deep water. The next frequency he tunes could destabilize a whole grid.
    
    The most troubling part is the device didn’t just end up in Voidspace by accident. Someone sent it there. Someone who either doesn’t understand the stakes or, worse, understands them all too well. Sabotage? An experiment gone rogue? I don’t know. But whoever did this knew what they were doing. They wanted Voidman to have it. Baker to have it. That thought alone makes my skin crawl.

    Maybe it was Baker himself, somehow. He's a genius - I'm sure he's figured out ways to manipulate the Voidspace. But these fractions of him, they're simple. They're stuck in these loops, the entropic chaos loops we call "missions" in the lab. It's essentially just replaying these memories of Baker's, the pranks and shenanigans that chaos savant got up to in the lab on a near daily basis, on an infinite loop. Baker was made for this - he's chaos incarnate. A fucking entropy machine.

    And now, literally.
    
    Amelia is running containment diagnostics, trying to track the distortions, but the energy patterns are of the chars. The “Enduckifier” frequency is relatively stable for now, but it’s already causing minor distortions. And Baker-Voidman-doesn’t know what he’s holding. Or maybe he does. His fractionation has stripped him of most of his awareness, but his instincts remain sharp. He's still got that Baker smartass wit, but for some reason with a shitty East coast accent. No idea, I just roll with it.
    
   There’s a part of me that wants to blame Baker, or Voidman, or whatever he is now, but this isn’t his fault. He’s fragmented, broken. He’s playing with something he doesn’t fully understand, but we’re the ones who put him in this state. We fractionated him, set him loose in Voidspace, and now we’re reaping the consequences.
    
    The bigger question is: who’s pulling the strings? Who wanted Voidman to have the device, and why? I’ve started combing through the lab’s security logs, but there’s nothing so far. Whoever did this covered their tracks well. Too well. Could it be The Observers?
    
    We’re running out of time. Amelia’s containment efforts will only hold for so long, and the distortions are already spreading. If Baker, or Voidman, or whatever piece of him is still in there, figures out what the device can really do, the consequences will be catastrophic.
    
    And yet, part of me can’t help but admire the absurdity of it all. Baker, the perpetual prankster, wielding a device capable of altering entire dimensions and using it to make ducks. It’s so him, in a way that’s both maddening and oddly comforting. Even fractured, he hasn’t lost that spark. The problem is, that spark could ignite an explosion none of us are prepared to face.
    
    I’m heading to Lab 3 now. We need to shut this down before it escalates. Then we figure out who’s behind this and why they seem intent on accelerating the chaos.
    
    The ducks are only the beginning.`
    },

    {
      id: 25,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Secrets and Portals',
      content: `I don’t like keeping things from Strauss. She’s brilliant, meticulous, pragmatic, relentless. If anyone could understand the stakes of our work, it would be her. But understanding isn’t enough. What we’re doing here, what we’ve already done, requires more than brilliance. It requires a willingness to accept that the rules don’t apply anymore. Strauss still believes in rules, in structure, in something resembling control. She wouldn’t agree with the risks we’re taking. She’d try to stop us, and we can’t afford that.
    
    So we keep her in the dark.
    
    Nick, Amelia, and I agreed early on that the truth about the Voidman experiments - the real truth - couldn’t leave this circle. As far as Strauss knows, we’re exploring entropy modulation and dimensional stabilization. She doesn’t know about ████████. She doesn’t know that the loops are our last, desperate attempt to delay it. To fight it. If you can even call it a fight.
    
    I hate lying to her. But the stakes are too high. If the loops collapse, ████████ wins. Everything ends. What choice do we have?
    
    Then there’s the Glowy Hole.
    
    Amelia’s report was bad enough - the Plug is dangerous even in controlled conditions, and Voidman has turned it into a toy. But the Glowy Hole, that’s something else entirely. A dimensional VPN, she called it. Whoever - or whatever - is sending these objects into Voidspace doesn’t want to be found. That kind of precision, that level of intent, it has to be The Observers.
    
    We’ve suspected their influence for a while now, but this is their boldest move yet. They’re not just watching the loops. They’re participating, directly interfering. The objects coming through the portal aren’t random. They’re tools, resources. They’re helping Voidman. Why? Is this their way of accelerating the chaos? Or are they trying to guide him toward something?
    
    And then there’s Voidman himself, or Baker, or whatever fragment of him still exists. He’s becoming more aware. The fact that he figured out how to “Unduckify” objects isn’t just a fluke. It’s a sign. His instincts are intact, his curiosity is growing, and his connection to the devices is deepening. If he pieces himself back together, if he becomes fully sentient within the loops, it could destabilize everything. 
    
    Strauss would say we’ve already lost control. I don’t think she’s wrong.
    
    Nick wants to trace the portal’s origin, but I’m not optimistic. The energy signature is obfuscated across so many dimensions that it might as well be invisible. If it is The Observers - and I’m certain it is - they don’t want us to find them. But we have to try. The Glowy Hole isn’t just a curiosity, it’s a fucking threat. A backdoor into Voidspace that bypasses every safeguard we’ve built.
    
    The loops are fragile enough as it is. Between the red voidman’s instability, Baker’s growing awareness, and now this portal, it feels like we’re holding everything together with duct tape, crossed fingers, and chewing gum. I can’t shake the feeling that we’re losing control. Not just of the experiment, but of the narrative itself. The Observers aren’t just watching us anymore. They’re shaping us. And I don’t know if we can stop them. I think it might be too late. We're in this until the end.
    
    Strauss asked me yesterday why I’ve been spending so much time in the observation room. I made up some excuse about recalibrating the kugelblitz protocols. She didn’t press, but she knows something is off. I need to be careful. If she starts digging, if she finds out what we’ve been hiding, I don’t know what she’d do. 
    
    I just know we can’t let her find out. Not yet.
    
    The Glowy Hole flickered on the monitor today, just for a moment. A faint, iridescent light, swirling with impossible colors. For a second, I thought I saw something move inside it - something reaching. But then it was gone.
    
    The loops have to hold. Strauss can’t know. And the portal needs to be closed before it destroys everything.`
    },

    {
      id: 26,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Into the Void',
      content: `Nick is gone.
    
    He went into the voidspace untethered. He said it had to be done, that the patterns were too clear to ignore. “The answer is in the absurd,” he told me yesterday, his voice calm, almost serene. “The FVNN, the mold, ████████ - it’s all part of the same equation. The noise, the chaos, the patterns, they're deliberate. Every thread is leading somewhere, even if we can’t see the whole weave.”
    
    I didn’t understand him then. I’m not sure I understand him now. But he believed it. Whatever he saw in those final days, whatever ████████ showed him, it convinced him. He said it didn’t matter if it seemed irrational, or impossible, or even suicidal. “It has to be done,” he said. “Not for us, but for what comes after.”

    What comes after.
    
    And now he’s gone. Just like Fletcher. Just like the others we’ve lost to the void.
    
    The tether system was our only safeguard. Without it, Nick had no way back. The moment he stepped into the voidspace, I knew we wouldn’t see him again. The void doesn’t let go. It consumes. It simplifies. 
    
    I’ve spent hours replaying the observation feeds, looking for something, anything, that might explain what happened. The voidspace swallowed him whole, leaving nothing behind but static and distortion. There’s no trace of him, no signature to follow. He’s just a smear of energy. Background noise.
    
    Part of me wants to believe he knew what he was doing. That he wasn’t just throwing himself into the void blindly, but acting on some deeper understanding. He spoke about patterns in a way I can’t stop thinking about, like he’d seen the algorithm of the universe itself, the function that governs all things, the infinite math equation finally solved. He believed he was part of it, that his actions would set something in motion. Maybe he was right. Or maybe he was just another victim of ████████.
    
    I can’t shake the feeling that this is my fault. I should have stopped him. I should have insisted on more tests, more safeguards. But how do you hold someone back when they’re so certain they’ve found the truth? Nick looked at me in those final moments, and there was no fear in his eyes. Just acceptance. As if he knew this was always how it would end.
    
    And now I’m alone again. First Fletcher, now Nick. I’ve lost the two people who kept me grounded, who reminded me why we were doing this in the first place. Without them, the noise feels deafening. The patterns are harder to see. And ████████ is always there, whispering, calling, pulling me toward it.
    
    I’ve tried to resist. To focus on the work, to keep the loops stable, to hold the edges together. But it’s getting harder. The more I see, the more I understand, the more I realize that resistance is futile. ████████ isn’t just inevitable, it’s necessary. It’s the resolution to the chaos, the function completing itself. Every attempt to stop it, to delay it, only feeds into its pattern. It’s not destruction. It’s balance. It’s simplicity.
    
    Nick saw that. Fletcher might have, too, in the end. Maybe they’re part of it now, part of the algorithm, part of ████████. I can’t tell if that thought comforts me or terrifies me. Maybe both.
    
    For now, I’ll keep going. I’ll do the work, stabilize the loops, manage the voidmen. But I can feel it - the pull, the inevitability. It’s getting louder. The threads are tightening, and I’m running out of reasons to resist.
    
    Someone has to see it through.`
    },

    {
      id: 27,
      date: 'DATE: ███████ TIME: ████████',
      title: 'Where’s My USB?',
      content: `I’ve lost the USB drive. This isn’t just any USB drive. This is THE USB drive. The one that had *Red Dwarf* Series 3 and 4 on it. I’d downloaded the episodes specifically for tonight - planned it out perfectly. Observation hours were going to be a breeze. I even had snacks ready. But now the fucking drive’s gone, and with it, any hope of unwinding.
    
    I was just gonna monitor Voidspace anomalies, keep an eye on the loops, and let Lister, Rimmer, and Cat distract me from the endless hum of entropy. But no. Someone - or something - decided to ruin my evening. The USB was supposed to be in the top drawer of my desk. I checked three times. It’s not there. Maybe I should check one more time.

    It's not there still.
     
    The worst part is that tonight’s episodes were going to be Backwards and Marooned. Classics! Do you know how long I’ve been looking forward to rewatching Cat try to eat backwards fish? Or Rimmer losing it over Lister burning his guitar? Weeks. Weeks of build-up, ruined in an instant. All because someone decided my USB drive was fair game.
    
    Amelia, bless her, suggested we check the lab security cameras. Someone has to know where it went. And if I find out Jenkins borrowed it to download more of his synthwave mixtapes, I swear I’ll revoke his lab access.
    
    Look, I get it. The lab deals with high-stakes experiments and reality-shattering discoveries every day. But sometimes you need a break. 
    
    Oh, and Baker’s red voidman fraction is on the drive too. Only takes up 12.3MB! Amazing compression, really.`
    },

    //if you're looking here, you've come too far. this is just one piece of the puzzle

];
export default ovaleJournal;
